import {Storage} from './Storage';

class SamplingWithoutReplacement {
  constructor(storage, arrayData) {
    if (!(storage instanceof Storage)) {
      throw new Error('storage must be an instance of Storage.');
    }
    if (arrayData.length < 1) {
      throw new Error('arrayData must not be empty.');
    }
    this.storage = storage;
    this.arrayData = Array.from(new Set(arrayData));
    this.remainingIndexes = this.storage.get() || [];
  }

  static _sampleOne(data, remainingIndexes){
    if (remainingIndexes.length === 0) {
      return [undefined, []];
    }
    let indexArray = [...remainingIndexes];
    const randomizedIndex = Math.floor(Math.random() * indexArray.length);
    const dataIndex = indexArray.splice(randomizedIndex, 1)[0];
    return [data[dataIndex], indexArray];
  }

  sample(){
    let sampledData = null;

    [sampledData, this.remainingIndexes] = SamplingWithoutReplacement._sampleOne(this.arrayData, this.remainingIndexes)
    if (!sampledData) {
      // For the case sampled data is invalid, remake remainingIndexes and retry once
      this.remainingIndexes = [...Array(this.arrayData.length)].map((_, i) => i);
      [sampledData, this.remainingIndexes] = SamplingWithoutReplacement._sampleOne(this.arrayData, this.remainingIndexes)
      if (!sampledData) {
        throw new Error('Failed to sample data. Element in data might be invalid.');
      }
    }
    this.storage.set(this.remainingIndexes);
    return sampledData;
  }
}

export default SamplingWithoutReplacement;