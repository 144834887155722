Object.defineProperty(Number.prototype, "between", {
    enumerable: false,
    value: function(min, max) { return this >= min && this < max; }
});

Object.defineProperty(Array.prototype, "removeDuplicates", {
    enumerable: true,
    value: function() { return this.filter((item, index) => this.indexOf(item) === index); }
});

const WordCount = (str) => {
  if (!str) return 0;
  return str.replace(/[.,\?!;:/&\n]/g, ' ').split(' ')
    .filter(function(n) { return n != '' })
    .length;
}

export {WordCount};
