import React, { Component } from "react";
import {Container, Row, Col} from 'react-bootstrap';
import {
  MDBRow, MDBCol, MDBContainer,
  MDBIcon, MDBCard, MDBCardBody, MDBCardFooter, MDBBtn, MDBCardTitle, MDBCardText, MDBRipple
}
from 'mdb-react-ui-kit';
import parse from 'html-react-parser';
import eventBus from "../EventBus";

export default class ContentCoherenceFeedbackCard extends Component {
  showChat = () => {
    eventBus.dispatch("chat", {
      'type': 'tag',
      'id': this.props.id,
      'text': this.props.feedback['texts'].map(text => parse(text))
    })
  }
  render() {
    return (
      <MDBCard id={`feedbackcard-${this.props.id}`} key={`feedbackcard-${this.props.id}`} border='dark' className={`feedback text-dark ${this.props.selected ? "selected" : "unselected"} ${this.props.hovered ? "hovered" : ""} mb-3`} feedbackid={this.props.id} onClick={this.props.onClick} onMouseOver={this.props.onMouseOver} onMouseOut={this.props.onMouseOut}>
        <MDBRipple role='button' rippleColor='#6dd6c2'>
          <MDBCardBody className="pb-0">
            <Container className="feedback-card p-0 small">
              <Row>
                <Col>
                {this.props.feedback['texts'].map((text, i) => (
                  <div key={`feedback-text-${this.props.id}`} className='mb-1'>
                  <MDBIcon fas icon="times-circle" className='me-2 text-danger'/>{parse(text)}</div>
                ))}
                </Col>
              </Row>
            </Container>
          </MDBCardBody>
          <MDBCardFooter className="feedback-footer pt-0">
            <MDBRow>
              <MDBCol className="d-flex align-items-center justify-content-end small text-muted">
                  <span className="me-1">UGUIS先生</span>
                  <MDBBtn floating size="sm" className="green-button" onClick={this.showChat}>
                    <MDBIcon far icon="comment"/>
                  </MDBBtn>
              </MDBCol>
            </MDBRow>
          </MDBCardFooter>
        </MDBRipple>
      </MDBCard>
    )
  }
}
