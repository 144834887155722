import { Container, Row, Col, Card, OverlayTrigger, Tooltip, Stack, Alert } from 'react-bootstrap';
import { MDBRipple, MDBCard, MDBCardBody, MDBBtn, MDBIcon, MDBTabs, MDBTabsItem, MDBTabsLink } from 'mdb-react-ui-kit';
import FeedbackTabContent from './FeedbackTabContent';
import React, { Component } from "react";
import SummaryScore from './SummaryScore';
import HeaderedScore from './HeaderedScore';
import eventBus from "../EventBus";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { SubtaskData } from "./SubTaskData";
import '../utils'

class Scores {
  constructor(type, feedback, max_score) {
    this.type = type;
    this.feedback = feedback;
    this.max_score = max_score;
  }
  get grammarScore() {
    return this.feedback.grammar_vocabulary.sys_grammar + 1
  }

  get vocabularyScore() {
    return this.feedback.grammar_vocabulary.sys_vocabulary + 1
  }

  get grammarVocabularyScore() {
    return Math.floor((this.grammarScore + this.vocabularyScore) / 2)
  }

  get contentScore() {
    return this.feedback.content.sys_content + 1;
  }

  get coherenceScore() {
    switch (this.type) {
      case "opinion":
        return this.feedback.coherence.sys_coherence + 1;
      case "summary":
        return this.feedback.coherence.sys_coherence + 1;
      case "email":
        return this.feedback.content.sys_content + 1;
      default:
        throw new Error(`unsupported ${this.type}`);
    }
  }

  get contentCoherenceScore() {
    return Math.floor((this.coherenceScore + this.contentScore) / 2)
  }

  get maxScore() {
    return this.max_score + 1;
  }

  get isGrammarFullScore() {
    return this.grammarScore == this.maxScore;
  }

  get isVocabularyFullScore() {
    return this.vocabularyScore == this.maxScore;
  }

  get isContentFullScore() {
    return this.contentScore == this.maxScore;
  }

  get isGrammarVocabularyFullScore() {
    return this.grammarVocabularyScore == this.maxScore
  }

  get isContentCoherenceFullScore() {
    return this.contentCoherenceScore == this.maxScore
  }
}

class Feedback extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'grammar_vocabulary'
    }

    this.scores = new Scores(props.question_data.type,
                             props.feedback,
                             props.question_data.get_max_score());
  }

  handleTabClick = (value) => {
    if (value === this.state.activeTab) {
      return;
    }
    this.setState(prevState => ({
      ...prevState,
      activeTab: value
    }))
  };

  selectEditorCallback = (data) => {
    if (data.type == 'tag') {
      this.setState(prevState => ({
        ...prevState,
        activeTab: 'content_coherence'
      }))
    } else if (data.type == 'sentence') {
      this.setState(prevState => ({
        ...prevState,
        activeTab: 'grammar_vocabulary'
      }))
    }
  }

  componentDidMount() {
    eventBus.on("selectEditor", this.selectEditorCallback);
  }

  componentWillUnmount() {
    eventBus.remove("selectEditor", this.selectEditorCallback);
  }
}

class FeedbackTab extends Component {
  render() {
    function responsiveText(text) {
      return (
        <>
          <div className="d-none d-md-block h6">{text}</div>
          <div className="d-block d-md-none">{text}</div>
        </>
      )
    }
    return (
      <MDBTabs justify className='mx-3 my-0'>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => {if (this.props.onTabChanged) this.props.onTabChanged('grammar_vocabulary')}} active={this.props.activeTab === 'grammar_vocabulary'} className="p-1 p-md-2 left">
            <Container className="text-center">
              <Row>
                {responsiveText("語彙・文法")}
              </Row>
              <Row>
                <HeaderedScore score={this.props.scores.grammarVocabularyScore} max_score={this.props.scores.maxScore} size={this.props.size}/>
              </Row>
            </Container>
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => {if (this.props.onTabChanged) this.props.onTabChanged('content_coherence')}} active={this.props.activeTab === 'content_coherence'} className="p-1 p-md-2 right">
            <Container className="text-center">
              <Row>
                { this.props.type === 'opinion' &&
                  responsiveText("内容・構成")
                }
                { this.props.type === 'summary' &&
                  responsiveText("内容・構成")
                }
                { this.props.type === 'email' &&
                  responsiveText("内容")
                }
              </Row>
              <Row>
                <HeaderedScore score={this.props.scores.contentCoherenceScore} max_score={this.props.scores.maxScore} size={this.props.size}/>
              </Row>
            </Container>
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>
    )
  }
}

class GrammarVocabularySummary extends Component {
  render() {
    return (
      <>
        <div>あなたの解答の語彙は<MDBIcon fas icon="star" style={{color: "orange"}}/>{this.props.scores.vocabularyScore}レベル、文法は<MDBIcon fas icon="star" style={{color: "orange"}}/>{this.props.scores.grammarScore}レベルです。</div>
        { !this.props.scores.isGrammarVocabularyFullScore &&
          <div>いくつか語彙や文法に誤りがありました。指摘された箇所を確認して改善に繋げましょう。</div>
        }
        { this.props.scores.isGrammarVocabularyFullScore &&
          <div>この級では問題なくクリアできています！</div>
        }
      </>
    )
  }
}

class ContentCoherenceSummary extends Component {

  constructor(props){
    super(props);
    let insufficientFeedback = null;
    if (props.type === 'opinion'){
      insufficientFeedback = <div>この問題では２つの理由を明確に書く必要があります。改善ポイントで指摘された箇所をよく確認して見直しましょう。</div>
    }
    else if(props.type === 'email'){
      if (props.grade === 'G30'){
        insufficientFeedback = <div>この問題では２つの質問に対する回答を明確に書く必要があります。改善ポイントで指摘された箇所をよく確認して見直しましょう。</div>
      }
      else if (props.grade === 'G25'){
        insufficientFeedback = <div>この問題では相手の質問への回答と併せて、理解を深めるための質問を２つ明確に書く必要があります。改善ポイントで指摘された箇所をよく確認して見直しましょう。</div>
      }
      else{
        throw new Error(`unsupported ${props.type} and ${props.grade}`);
      }
    }
    else if(props.type ==='summary'){
      if (props.grade === 'G15'){
        insufficientFeedback = <div>この問題では採点後の解答欄で示される４つのポイントを明確に書く必要があります。改善ポイントで指摘された箇所をよく確認して見直しましょう。</div>
      }
      else if (props.grade === 'G20'){
        insufficientFeedback = <div>この問題では採点後の解答欄で示される３つのポイントを明確に書く必要があります。改善ポイントで指摘された箇所をよく確認して見直しましょう。</div>
      }
      else{
        throw new Error(`unsupported ${props.type} and ${props.grade}`);
      }
    }
    else{
      throw new Error(`unsupported ${props.type}`);
    }
    this.insufficientFeedback = insufficientFeedback;
  }

  render() {
    return (
      <>
        <div>
          あなたの解答の内容は<MDBIcon fas icon="star" style={{color: "orange"}}/>{this.props.scores.contentScore}レベル
          {(this.props.type === 'opinion' || this.props.type === 'summary') &&
          <span>、構成は<MDBIcon fas icon="star" style={{color: "orange"}}/>{this.props.scores.coherenceScore}レベル</span>
          }
          です。
        </div>
        { !this.props.scores.isContentCoherenceFullScore &&
          this.insufficientFeedback
        }
        { this.props.scores.isContentCoherenceFullScore &&
          <div>この級では問題なくクリアできています！</div>
        }
      </>
    )
  }
}

class VerticalFeedback extends Feedback {
  render() {
    return (
      <Container className="feedback-sticky-container">
        <Row>
          <Container className="mb-3">
            <SummaryScore
              feedback={this.props.feedback}
              type={this.props.question_data.type}
              max_score={this.scores.maxScore}
            />
          </Container>
        </Row>
        <Row className='feedback-pane'>
          <Container className="mb-3">
            <Card className='feedback-card-pane'>
              <Card.Header className='py-md-2 py-1'>
                <div className='card-headder-margin'>
                  <MDBIcon far icon="lightbulb me-2 align-middle" />
                  <span className="align-middle fw-bold">観点評価と改善のポイント</span>
                </div>
              </Card.Header>
              <Card.Body>
                <FeedbackTab type={this.props.question_data.type} activeTab={this.state.activeTab} onTabChanged={this.handleTabClick} scores={this.scores}/>
                <Row className="mt-3">
                  <Container className="my-3">
                    {this.state.activeTab === 'grammar_vocabulary' &&
                      <>
                        <span className='me-3'>
                          <HeaderedScore
                            header="語彙："
                            score={this.scores.vocabularyScore}
                            max_score={this.scores.maxScore}
                          />
                        </span>
                        <span>
                          <HeaderedScore
                            header="文法："
                            score={this.scores.grammarScore}
                            max_score={this.scores.maxScore}
                          />
                        </span>
                        <Row className="mt-3">
                          <GrammarVocabularySummary scores={this.scores}/>
                        </Row>
                      </>
                    }
                    {this.state.activeTab === 'content_coherence' &&
                      <>
                        <span className='me-3'>
                        <HeaderedScore
                          header="内容："
                          score={this.scores.contentScore}
                          max_score={this.scores.maxScore}
                        />
                        </span>
                        {(this.props.question_data.type === 'opinion' || this.props.question_data.type === 'summary') &&
                          <span>
                            <HeaderedScore
                              header="構成："
                              score={this.scores.coherenceScore}
                              max_score={this.scores.maxScore}
                            />
                            </span>
                        }
                        <Row className="mt-3">
                          <ContentCoherenceSummary
                            scores={this.scores}
                            type={this.props.question_data.type}
                            grade={this.props.question_data.grade}
                          />
                        </Row>
                      </>
                    }
                  </Container>
                </Row>
                <Row>
                  {this.state.activeTab === 'content_coherence' &&
                    <FeedbackTabContent direction="vertical" type="tag" feedbacks={getContentCoherenceFeedbacks(this.props.feedback, this.props.question_data)}/>
                  }
                  {this.state.activeTab === 'grammar_vocabulary' &&
                    <FeedbackTabContent direction="vertical" type="sentence" feedbacks={this.props.feedback.grammar_vocabulary.sentences}/>
                  }
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </Row>
      </Container>
    );
  }
}

function getContentCoherenceFeedbacks(feedback, question_data) {
  const type = question_data.type
  const grade = question_data.grade

  function pushTagFeedback(detailedFeedbacks, sentences, template) {
    // console.log('pushing', template, sentences)
    let show = false
    let validFeedbacks = template.feedbacks.filter(f => f[0])
    if (template.tagMustExist) {
      if (template.tag) {
        if (sentences) {
          let index = sentences.findIndex(sentence => sentence.tag == template.tag)
          if (index >= 0) {
            if (validFeedbacks.some(v => true)) {
              show = true
            }
          }
        }
      }
    }
    else {
      if (validFeedbacks.some(v => true)) {
        show = true
      }
    }

    if (show) {
      detailedFeedbacks.push({
        tag: template.tag,
        texts: validFeedbacks.map(f => f[1])
      })
    }
  }

  let detailedFeedbacks = []
  if (feedback !== null){
    let sentences = feedback.grammar_vocabulary.sentences
    let tagFeedbackTemplates = [
      {tag: 'coherence.checklist_2', tagMustExist: false, feedbacks: [
        [feedback.coherence?.checklist_2, '文章が短すぎます。もっと内容を書きましょう。'],
      ]},
      {tag: 'coherence.checklist_20', tagMustExist: false, feedbacks: [
        [feedback.coherence?.checklist_20, '文章が少し短いです。もう少し内容を書きましょう。'],
      ]},
      {tag: 'coherence.checklist_22', tagMustExist: false, feedbacks: [
        [feedback.coherence?.checklist_22, 'ポイントについての内容はまとめて書きましょう。同じポイントについて前で書いたり後ろで書いたりしないようにしましょう。'],
      ]},
      {tag: 'coherence.checklist_29', tagMustExist: false, feedbacks: [
        [feedback.coherence?.checklist_29, '主題の要約の位置が不適切です。主題は最初に要約するようにしましょう。'],
      ]},
      {tag: 'coherence.order_issue', tagMustExist: false, feedbacks: [
        [(feedback.coherence?.checklist_32 || feedback.coherence?.checklist_34),
          '元の英文と、要約文の内容の順番を揃えましょう。'],
      ]},
      {tag: 'content.offtopic_score', tagMustExist: false, feedbacks: [
        [type == 'opinion' && (feedback.content.offtopic_score == 0
          || feedback.content.offtopic_score == null), '質問に関係のない内容を書いているようです。質問に答えましょう。'],
      ]},
      {tag: 'content.num_else', tagMustExist: false, feedbacks: [
        [type == 'summary' && (feedback.content.num_else >= 1
          || feedback.content.num_else == null),
         '余分な内容を省き要点をまとめましょう。'],
      ]},
      {tag:'Intro', tagMustExist: true, feedbacks: [
        [feedback.content.checklist_15, '適切なリード文がありません。'],
        [feedback.content.opinion_label, 'もっとお題と関係のある内容を書きましょう。'],
      ]},
      {tag:'Opinion', tagMustExist: true, feedbacks: [
        [feedback.content.checklist_15, '適切なリード文がありません。'],
        [feedback.content.opinion_label, 'もっとお題と関係のある内容を書きましょう。'],
      ]},
      {tag:'R0', tagMustExist: true, feedbacks: [
        [feedback.content.p0_checklist_results?.checklist_4, 'こちらの理由をもう少し詳しく書きましょう。'],
        [feedback.content.p0_checklist_results?.checklist_9, 'こちらの理由はトピックとあまり関係ないようです。もう少し関係のある理由を書きましょう。'],
        [feedback.content.p0_checklist_results?.checklist_10, 'こちらの理由をもう少し詳しく書きましょう。'],
        // we have all the corrections in the main text already
        // [feedback.content.p0_checklist_results.checklist_11, '英単語を使用してください。'],
        // [feedback.content.p0_checklist_results.checklist_12, '理由が不十分な文です'],
        [feedback.content.p0_checklist_results?.task_completion_status == 1, '質問に対して適切に答えていません。'],
        // TODO: address in sentence comment
        // [feedback.content.p0_checklist_results && feedback.content.p0_checklist_results.incomplete_score == 1, '不完全な文です。'],
        // [feedback.content.p0_checklist_results && feedback.content.p0_checklist_results.sentence_score == 2, '文の意味に影響する文法の誤りがあります。'],
      ]},
      {tag:'EX0', tagMustExist: true, feedbacks: [[feedback.content.p0_checklist_results?.checklist_345, 'こちらの理由に対する説明をもう少し詳しく書きましょう。']]},
      {tag:'R1', tagMustExist: true, feedbacks: [
        [feedback.content.p1_checklist_results?.checklist_4, 'こちらの理由をもう少し詳しく書きましょう。'],
        [feedback.content.p1_checklist_results?.checklist_9, 'こちらの理由はトピックとあまり関係ないようです。もう少し関係のある理由を書きましょう。'],
        [feedback.content.p1_checklist_results?.checklist_10, 'こちらの理由をもう少し詳しく書きましょう。'],
        // we have all the corrections in the main text already
        // [feedback.content.p1_checklist_results?.checklist_11, '英単語を使用してください。'],
        // [feedback.content.p1_checklist_results?.checklist_12, '理由が不十分な文です'],
        [feedback.content.p1_checklist_results?.task_completion_status == 1, '質問に対して適切に答えていません。'],
        // TODO: address in sentence comment
        // [feedback.content.p1_checklist_results?.incomplete_score == 1, '不完全な文です'],
        // [feedback.content.p1_checklist_results?.sentence_score == 2, '文の意味に影響する文法の誤りがあります'],
      ]},
      {tag:'EX1', tagMustExist: true, feedbacks: [[feedback.content.p1_checklist_results?.checklist_345, 'こちらの理由に対する説明をもう少し詳しく書きましょう。']]},
      {tag:'Conclusion', tagMustExist: true, feedbacks: [
        [feedback.content.checklist_16, '適切な結論がありません。'],
        [feedback.content.checklist_17, 'リード文と結論の関係性が明確ではありません。'],
      ]},
    ]

    if (type === 'email'){
      const subtask_templates_list = SubtaskData[grade][type]['feedback_templates']['content']
      subtask_templates_list.forEach(templates_subtask => {
        const label_name = templates_subtask[0] + '_subtask_label'
        tagFeedbackTemplates.push(
          {
            tag: templates_subtask[0], tagMustExist: false, feedbacks: [
              [feedback.content[label_name] >= 1, templates_subtask[1]],
              [feedback.content[label_name]?.between(0.5, 1), templates_subtask[2]]
            ]
          }
        )
      })
    }
    else if(type === 'summary'){
      let subtask_templates_list;
      if (grade === 'G15'){
        subtask_templates_list = SubtaskData[grade][type]['feedback_templates']['content'][question_data.passage_type]
      }
      else if (grade === 'G20'){
        subtask_templates_list = SubtaskData[grade][type]['feedback_templates']['content']
      }
      else{
        throw new Error(`Unsupported grade: ${grade}`);
      }
      subtask_templates_list.forEach(templates_subtask => {
        const label_name = templates_subtask[0] + '_subtask_label'
        let model_sentence_feedback = '';
        // if (feedback.content[templates_subtask[0]]['model_sentence'] != null){
        //   model_sentence_feedback = '下の模範解答を参考にしながら要約しなおしてみましょう。<br/><div class="p-1 m-1" style="border: 1px solid #000000">' + feedback.content[templates_subtask[0]]['model_sentence'] + '</div>'
        // }
        tagFeedbackTemplates.push(
          {
            tag: templates_subtask[0], tagMustExist: false, feedbacks: [
              [feedback.content[label_name] >= 1, templates_subtask[1] + model_sentence_feedback],
              [feedback.content[label_name]?.between(0.5, 1), templates_subtask[2] + model_sentence_feedback],
              [feedback.content[templates_subtask[0]]?.copy_score >= 1, '自分の言葉に置き換えて要約しましょう。']
            ]
          }
        )
      })
    }
    else if(type === 'opinion'){
      // no type specific process for opinion
      ;
    }
    else{
      throw new Error(`Unsupported type: ${type}`);
    }


     tagFeedbackTemplates.forEach(template => {
      pushTagFeedback(detailedFeedbacks, sentences, template)
    })

    // console.log(detailedFeedbacks)
    return detailedFeedbacks;
  }
}

class HorizontalFeedback extends Feedback {
  sizeObserver;

  constructor(props) {
    super(props);
    this.modes = [
      "keyboard",
      "feedback"
    ]
    this.state = {...this.state,
      mode: this.modes.indexOf("feedback")
    }
  }

  handleModeChange = async(e) => {
    this.setState({...this.state,
      mode: ((this.state.mode + 1) % this.modes.length)},
      () => {
        if (this.props.onModeChange) {
          this.props.onModeChange(this.modes[this.state.mode]);
        }
      });
  }

  componentDidMount() {
    if (this.props.onModeChange) {
      this.props.onModeChange(this.modes[this.state.mode]);
    }

    // console.log('componentDidMount')
    if (this.sizeObserver == null) {
      const feedbackPane = document.getElementById('horizontal-feedback');
      if (feedbackPane) {
        this.sizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            if (this.props.sizeUpdated) {
              this.props.sizeUpdated(entry.contentRect);
            }
          }
        });
        this.sizeObserver.observe(feedbackPane);
      }
    }
  }

  componentWillUnmount() {
    this.sizeObserver?.disconnect();
    this.sizeObserver = null;
  }

  get modeIcon() {
    return this.modes[this.state.mode] == "keyboard" ? "chevron-up" : "chevron-down"
  }

  get modeLabel() {
    return this.modes[this.state.mode] == "keyboard" ? "評価を見る" : "評価を閉じて解答修正"
  }

  render() {
    return (
      <Container id="horizontal-feedback" className="fixed-bottom" style={{backgroundColor: "white", zIndex:1100, borderTop: "1px solid silver", borderTopLeftRadius:'15px', borderTopRightRadius:'15px'}}>
        <div className="d-flex align-items-center justify-content-between py-2">
          <MDBBtn className="gradient-bubble outline p-2" onClick={this.handleModeChange}>
            <MDBIcon fas icon={ this.modeIcon } className="me-1" />
            <span>{ this.modeLabel  }</span>
          </MDBBtn>
          <SummaryScore
            fluid
            feedback={this.props.feedback}
            type={this.props.question_data.type}
            max_score={this.scores.maxScore}
            size='sm'
          />
        </div>
        {this.modes[this.state.mode] == "feedback" && (
          <>
            <div className="px-3">
              <FeedbackTab type={this.props.question_data.type} activeTab={this.state.activeTab} onTabChanged={this.handleTabClick} scores={this.scores} size='xs'/>
            </div>
            <div className="mt-2">
              {this.state.activeTab === 'grammar_vocabulary' && (
                <>
                  <Container className="feedback-card small p-2">
                    <GrammarVocabularySummary scores={this.scores}/>
                  </Container>
                  <FeedbackTabContent direction="horizontal" type="sentence" feedbacks={this.props.feedback.grammar_vocabulary.sentences}/>
                </>
              )}
              {this.state.activeTab === 'content_coherence' && (
                <>
                <Container className="feedback-card small p-0">
                  <ContentCoherenceSummary
                    scores={this.scores}
                    type={this.props.question_data.type}
                    grade={this.props.question_data.grade}
                  />
                </Container>
                <FeedbackTabContent direction="horizontal" type="tag" feedbacks={getContentCoherenceFeedbacks(this.props.feedback, this.props.question_data)}/>
                </>
              )}
            </div>
          </>
        )}
      </Container>
    );
  }
}

export { VerticalFeedback, HorizontalFeedback };
