import { createContext, useState, useEffect } from "react"
import * as auth from "./auth"
import * as api from "../api"
import { Navigate } from "react-router-dom";
import TermOfServiceModal from "../TermOfServiceModal";

const AuthContext = createContext()

function AuthProvider({ children }) {
  const [user, setUser] = useState(null)
  const [profile, setProfile] = useState(null)
  const [systemConfig, setSystemConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(true)
  const [showNavBar, setShowNavBarState] = useState(true)
  const [showTermOfServiceModal, setShowTermOfServiceModal] = useState(false);

  const getUserProfile = async () => {
    try {
      const profile = await api.getUserProfile();
      if (systemConfig && (!profile.accepted_tos_version || profile.accepted_tos_version < systemConfig.tos_version)) {
        setShowTermOfServiceModal(true);
      }
      setProfile(profile);
    } catch (err) {
      setProfile(null);
    }
  };
  
  const getCurrentUser = async () => {
    try {
      const currentUser = await auth.getCurrentUser();
      if (currentUser !== user) {
        setUser(currentUser);
      }
    } catch (err) {
      // not logged in
      console.log(err);
      if (user !== null) {
        setUser(null);
      }
    }
    setIsLoading(false);
  };
  

  const getSystemConfig = async () => {
    try {
      const config = await api.getSystemConfig();
      setSystemConfig(config);
    } catch (err) {
      console.error("Failed to fetch system config", err);
    }
  };
  

  useEffect(() => {
    const fetchConfigAndUser = async () => {
      await getSystemConfig();
      await getCurrentUser().catch(() => setIsLoading(false));
    };
    fetchConfigAndUser();
  }, []);

  useEffect(() => {
    if (user && systemConfig) {
      getUserProfile();
    }
  }, [user, systemConfig]);

  const handleAcceptTOS = async () => {
    try {
      const systemConfig = await api.getSystemConfig();
      const response = await api.updateAcceptedTosVersion(systemConfig.tos_version);
      if (response) {
        setShowTermOfServiceModal(false);
        getUserProfile(); // Update profile after accepting TOS
        return true;
      } else {
        console.error('Failed to update accepted_tos_version');
        return false;
      }
    } catch (err) {
      console.error('An error occurred while updating accepted_tos_version');
      return false;
    }
  };

  const signIn = async (username, password) => {
    await auth.signIn(username, password)
    await getCurrentUser()
  }
  const signOut = async () => {
    await auth.signOut()
    setUser(null)
    window.location.href = '/'
  }

  const setShowNavBar = async(show) => {
    setShowNavBarState(show)
  }


  const authValue = {
    user,
    profile,
    isLoading,
    signIn,
    signOut,
    showNavBar,
    setShowNavBar,
    getCurrentUser,
  }

  return (
    <AuthContext.Provider value={authValue}>
      {children}
      {showTermOfServiceModal && (
        <TermOfServiceModal show={showTermOfServiceModal} handleAcceptTOS={handleAcceptTOS} handleSignOut={signOut} />
      )}
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext }
