export default function EikenTrademarkNote() {
  return (
    <div className="blockquote-footer m-0">
      <div>
        ※ 本サービスは予告なくサービスが一時停止する場合がございます。予めご了承ください
      </div>
      <div>
        ※ 英検<sup>®</sup>は、公益財団法人日本英語検定協会の登録商標です。このコンテンツは、公益財団法人 日本英語検定協会の承認や推奨、その他の検討を受けたものではありません
      </div>
    </div>
  )
}
