// import TextDiff from './TextDiff';
//
// function TextCorrection(props) {
//   let org_txt = props.org_txt;
//   let cor_txt = props.cor_txt;
//   const mergedDiff = new TextDiff().getDiff(org_txt, cor_txt);
//   return mergedDiff.map((part, i) => {
//     // green for additions, red for deletions
//     // black for common parts
//     const color = part.added ? 'green' : part.removed ? 'red' : 'black';
//     const decoration = part.removed ? 'line-through':'none'
//     return <span style={{color: color, 'fontFamily': "monospace", 'textDecoration': decoration}} key={i}>{part.value}</span>;
//   });
// }
//
// export default TextCorrection;
//
//

import {
  MDBIcon,
} from 'mdb-react-ui-kit';

import DiffEx from './DiffEx';
const Diff = require('diff');

function TextCorrection(props) {
  let org_txt = props.org_txt;
  let cor_txt = props.cor_txt;
  let diffWords = props.diff_words ?? true;
  const diffs = DiffEx.diff(org_txt, cor_txt, diffWords);
  // console.log(diffs)
  return (
    <div className="lh-lg">
    {
      diffs.map((part, i) => {
    return (
      <>
      {part.action == null &&
        <>
        <span style={{color: 'black', 'fontFamily': "monospace"}}>{part.from}</span>
        </>
      }
      {part.action == 'added' &&
        <>
        <ruby>
          <MDBIcon fas icon="angle-down mx-2 text-success"/>
          <rp>(</rp>
          <rt>
            <span style={{color: 'green', 'fontFamily': "monospace"}}>{part.to}</span>
          </rt>
          <rp>)</rp>
        </ruby>
        </>
      }
      {part.action == 'removed' &&
        <>
        <span style={{color: 'red', 'fontFamily': "monospace", 'textDecoration':'line-through'}} key={i}>{part.from}</span>
        </>
      }
      {part.action == 'replaced' &&
        <>
        <ruby>
          <span style={{color: 'red', 'fontFamily': "monospace", 'textDecoration':'line-through'}} key={i}>{part.from}</span>
          <rp>(</rp>
          <rt>
            <span style={{color: 'green', 'fontFamily': "monospace"}} key={i}>{part.to}</span>
          </rt>
          <rp>)</rp>
        </ruby>
        </>
      }
      </>
    )
  })
    }
    </div>);
}

export default TextCorrection;
