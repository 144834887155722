import statement from './config/Statement.json'
import assignments from './config/SampleAssignments.json'

var Statement = statement;
var SampleAssignments = assignments;

export {
  Statement,
  SampleAssignments,
}
