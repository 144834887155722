export default function EikenLogo(props) {
  const size = props.size ?? 'lg'
  const fontSizes = {
    'lg': ['h1', 'h2', 'h4'],
    'md': ['h2', 'h3', 'h5'],
    'sm': ['h4', 'h5', 'h6'],
  }

  switch (props.grade) {
    case "G15":
      return (
        <span>
          <span className={`fw-bold ${fontSizes[size][1]}`}>英検<sup>®</sup></span>
          <span className={`fw-bold ${fontSizes[size][2]} align-top`}>準</span>
          <span className={`fw-bold ${fontSizes[size][0]}`}>１</span>
          <span className={`fw-bold ${fontSizes[size][2]} align-bottom`}>級</span>
        </span>
      )
    case "G20":
      return (
        <span>
          <span className={`fw-bold ${fontSizes[size][1]}`}>英検<sup>®</sup></span>
          <span className={`fw-bold ${fontSizes[size][0]}`}>２</span>
          <span className={`fw-bold ${fontSizes[size][2]} align-bottom`}>級</span>
        </span>
      )
    case "G25":
      return (
        <span>
          <span className={`fw-bold ${fontSizes[size][1]}`}>英検<sup>®</sup></span>
          <span className={`fw-bold ${fontSizes[size][2]} align-top`}>準</span>
          <span className={`fw-bold ${fontSizes[size][0]}`}>２</span>
          <span className={`fw-bold ${fontSizes[size][2]} align-bottom`}>級</span>
        </span>
      )
    case "G30":
      return (
        <span>
          <span className={`fw-bold ${fontSizes[size][1]}`}>英検<sup>®</sup></span>
          <span className={`fw-bold ${fontSizes[size][0]}`}>３</span>
          <span className={`fw-bold ${fontSizes[size][2]} align-bottom`}>級</span>
        </span>
      )
    default:
      return null
  }
}
