import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from "./Auth/AuthContext"
import {
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBBtn,
  MDBInput,
} from 'mdb-react-ui-kit';
import { useNavigate, Link } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import { changePassword, deleteUser } from "./Auth/auth"
import Dialog from 'react-bootstrap-dialog'
import * as api from './api'
import { PasswordChecker, checkPassword } from './Auth/PasswordChecker'

export default function Setting(props) {
  const { user, signOut, profile } = useContext(AuthContext)

  const navigate = useNavigate();

  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPassword2, setNewPassword2] = useState("")
  const [changePasswordError, setChangePasswordError] = useState("")
  const [changePasswordSuccess, setChangePasswordSuccess] = useState("")
  const [deleteAccountError, setDeleteAccountError] = useState("")
  const [deleteAccountSuccessful, setDeleteAccountSuccessful] = useState(false)

  var dialog;

  useEffect(() => {
    if (deleteAccountSuccessful) {
      showDeleteAccountSuccess();
    }
  }, [deleteAccountSuccessful])

  const handleChangePassword = async (e) => {
    setChangePasswordError("");

    if (!checkPassword(newPassword, newPassword2)) {
      setChangePasswordError("新しいパスワードが条件を満たしていないか、確認用パスワードが一致していません。")
      return
    }

    try {
      await changePassword(oldPassword, newPassword);
      setChangePasswordSuccess("パスワードを変更できました。")
    } catch (err) {
      if (err.name == 'NotAuthorizedException') {
        setChangePasswordError(`現在のパスワードは正しくありません。`)
      }
      else if (err.name == 'LimitExceededException') {
        setChangePasswordError(`試行回数の上限に達しました。しばらくしてから再度お試しください。`)
      }
      else {
        setChangePasswordError(`${err.name}: ${err.message}`)
      }
    }
  }

  const showDeleteAccountSuccess = () => {
    dialog.show({
      title: 'アカウントを削除しました。',
      body: 'ご利用ありがとうございました。',
      actions: [
        Dialog.DefaultAction(
          '完了',
          () => {
            signOut();
          },
          'btn-success'
        )
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        signOut();
      }
    })
  }

  const deleteAccount = async () => {
    try {
      await api.deleteUserAccount()

      try {
        await deleteUser()
      } catch (err) { }

      setDeleteAccountSuccessful(true);

    } catch (err) {
      setDeleteAccountError(`${err.name}: ${err.message}`)
    }
  }

  const handleDeleteAccount = async (e) => {
    dialog.show({
      title: 'アカウントを削除してよろしいですか？',
      body: 'アカウントを削除しますと、過去の解答や採点結果はすべて削除され、取り戻すことはできません。',
      actions: [
        Dialog.Action(
          'キャンセル',
          () => dialog.hide(),
          'btn-secondary'
        ),
        Dialog.DefaultAction(
          '削除',
          deleteAccount,
          'btn-danger'
        )
      ],
      bsSize: 'small',
      onHide: (dialog) => {
        dialog.hide()
      }
    })
  }

  return (
    <MDBContainer>
      <MDBRow center>
        <MDBCol md={8}>
          <MDBRow className="m-5">
            <MDBCol>
              <MDBRow className="mb-3">
                <div className="h2 fw-bold">メールアドレス</div>
              </MDBRow>
              <MDBRow>
                <h6>{profile?.email}</h6>
              </MDBRow>
            </MDBCol>
          </MDBRow>
          <hr className='hr hr-blurry' />
          <MDBRow className="m-5">
            <MDBCol>
              <MDBRow className='mb-3'>
                <div className="h2 fw-bold">パスワード変更</div>
              </MDBRow>
              <MDBRow className='mb-3'>
                <MDBCol>
                  <MDBInput type='password' id='oldPassword' label='現在のパスワード' value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                </MDBCol>
              </MDBRow>
              <MDBRow className='mb-3'>
                <MDBCol>
                  <MDBInput type='password' id='newPassword' label='新しいパスワード' value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                </MDBCol>
              </MDBRow>
              <MDBRow className='mb-3'>
                <MDBCol>
                  <MDBInput type='password' id='newPassword2' label='新しいパスワード(確認用)' value={newPassword2} onChange={(e) => setNewPassword2(e.target.value)} />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <PasswordChecker password={newPassword} confirmPassword={newPassword2} />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol className="text-end">
                  <MDBBtn className="green-button" onClick={handleChangePassword} disabled={!oldPassword || !checkPassword(newPassword, newPassword2)}>変更</MDBBtn>
                </MDBCol>
              </MDBRow>
              {changePasswordError &&
                <MDBRow className="my-3">
                  <Alert variant="danger">
                    {changePasswordError}
                  </Alert>
                </MDBRow>
              }
              {changePasswordSuccess &&
                <MDBRow className="my-3">
                  <Alert variant="success">
                    {changePasswordSuccess}
                  </Alert>
                </MDBRow>
              }
            </MDBCol>
          </MDBRow>
          <hr className='hr hr-blurry' />
          <MDBRow className="m-5">
            <MDBCol>
              <MDBRow className='mb-3'>
                <Link className="text-muted" onClick={handleDeleteAccount}>
                  アカウントを削除
                </Link>
              </MDBRow>
              {deleteAccountError &&
                <MDBRow className="my-3">
                  <Alert variant="danger">
                    {deleteAccountError}
                  </Alert>
                </MDBRow>
              }
            </MDBCol>
          </MDBRow>
        </MDBCol>
      </MDBRow>
      <Dialog ref={(el) => { dialog = el }} />
    </MDBContainer>
  )
}
