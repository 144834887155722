import React, { Component } from "react";
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBSpinner,
  MDBRipple
} from 'mdb-react-ui-kit';

import Modal from 'react-bootstrap/Modal'
import ReactMarkdown from 'react-markdown'

import * as api from "../api"

import { Statement } from './SampleData';

import { TypeAnimation } from 'react-type-animation';

import { Card } from 'react-bootstrap';

import {delay} from '../retry'

export default class ChatDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentChat: "",
      // chats: [['ai', 'アドバイス'], ['user', 'わかりません']],
      initialChats: [],
      followingChats: [],
      loading: true,
      isInitialReady: false,
      isInitialDone: false,
    }
  }

  async startChat(assignment, feedback, essay) {
    // this.appendMessage('user', 'アドバイスをください。私はどんなことをあなたに聞けるかも教えてください。');
    this.clearChatHistory();
    this.appendMessage('ai', 'えっと、ちょっと待ってください。', true);
    this.setLoading(true)

    this.threadid = await api.create_chat();

    const statementData = Statement[assignment.question.grade][assignment.question.type]
    let response = await api.chat(this.threadid, {
      'statement': statementData,
      'assignment': assignment,
      'essay': essay,
      'feedback': feedback
    })
    this.setLoading(false)
    // console.log(response)
    this.appendMessage('ai', response, true);
    this.setState(prevState => ({...prevState, isInitialReady:true}))
  }

  setLoading(loading) {
    this.setState(prevState => ({
      ...prevState,
      "loading": loading
    }));
  }

  appendMessage(role, message, isInitial=false) {
    if (isInitial) {
      this.setState(prevState => {
        let chats = prevState.initialChats;
        chats.push([role, message]);
        return {
          ...prevState,
          initialChats : chats
        };
      });
    }
    else {
      this.setState(prevState => {
        let chats = prevState.followingChats;
        chats.push([role, message]);
        return {
          ...prevState,
          followingChats : chats
        };
      });
    }
  }

  typeAnimationComplete = () => {
    if (this.state.isInitialReady) {
      this.setState(prevState => ({...prevState, isInitialDone:true}))
    }
  }

  clearChatHistory() {
    this.setState(prevState => ({
      ...prevState,
      initialChats: [],
      followingChats: [],
      loading: false,
      isInitialReady: false,
      isInitialDone: false,
    }))
  }

  handleSelectChatCard(text) {
    this.submitChat(text);
  }

  renderCards() {
    const cards = [
      {"title": "もっとアドバイスをください", "prompt": "もっとアドバイスをください"},
      {"title": "ヒント出して", "prompt": "ヒント出して"},
      {"title": "解答例出して", "prompt": "解答例出して"},
      {"title": "模範解答出して", "prompt": "模範解答出して"},
      {"title": "問題和訳して", "prompt": "問題和訳して"},
      {"title": "別解答教えて", "prompt": "別解答教えて"},
    ]
    return (
      <div className="d-flex flex-row flex-wrap m-3">
      {
        cards.map(card => {
          return (
            <div className="chat-card">
              <MDBRipple role='button' rippleColor='#6dd6c2' onClick={() => this.handleSelectChatCard(card["prompt"])} className="h-100 w-100">
                <div className="m-2 small">
                  <MDBIcon far icon="lightbulb" className="me-2"/>
                  {card["title"]}
                </div>
              </MDBRipple>
            </div>
          )
        })
      }
      </div>
    )

  }

  clearCurrentChat() {
    this.setState(prevState => ({
      ...prevState,
      currentChat: "",
    }))
  }

  endChat() {
    this.clearCurrentChat();
    this.clearChatHistory();
    this.setLoading(true);
    if (this.threadid) {
      api.delete_chat(this.threadid);
      this.threadid = null;
      this.setState(prevState => ({
        ...prevState,
        chats: []
      }));
    }
  }

  handleChatChange = (event) => {
    this.setState({...this.state,
      currentChat : event.target.value,
    });
  }

  scrollToBottom() {
    document.getElementById(`chatinput`).scrollIntoView({ behavior: 'smooth', block: 'start', inline:'start'})
  }

  handleChatSubmit = async () => {
    let text = this.state.currentChat;
    this.submitChat(text)
    this.clearCurrentChat();
  }

  submitChat(text) {
    this.appendMessage('user',  text)
    delay(async () => {
      this.setLoading(true);
      this.scrollToBottom();
      let response = await api.chat(this.threadid, {
        'message': text
      });
      this.setLoading(false);
      if (response){
        this.appendMessage('ai', response);
      }
      this.scrollToBottom();
    }, 750);
  }

  onEnterPress = (e) => {
    if(e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      this.handleChatSubmit();
    }
  }

  renderChat(chat) {
    return chat[0] == 'ai' ? (
        <div className="d-flex flex-row justify-content-start mb-4">
          <img
            src="profile.png"
            className="rounded-circle"
            height="32"
            loading="lazy"
          />
          <div
            className="py-2 px-2 px-md-4 ms-2 ms-md-3"
            style={{
              borderRadius: "15px",
              backgroundColor: "var(--light-green)",
            }}
          >
            <TypeAnimation className="small"
              sequence={
                [chat[1],
                this.typeAnimationComplete
              ]}
              speed={75}
              cursor={false}
              style={{ whiteSpace: 'pre-line' }}

            />
          </div>
        </div>
      ) : (
        <div className="d-flex flex-row justify-content-end mb-4">
          <div
            className="py-2 px-2 px-md-4 me-2 me-md-3 border"
            style={{ borderRadius: "15px", backgroundColor: "var(--light-gray)" }}
          >
            <div className="small">{chat[1]}</div>
          </div>
          <MDBIcon fas icon="user-circle" size='2x' style={{color:"silver"}}/>
        </div>
      )
  }

  render() {
    return (
      <Modal
        {...this.props}
        fullscreen="xs" size='lg'
        aria-labelledby="contained-modal-title-vcenter"
        onHide={() => {
          this.endChat();
          this.props.onHide();
        }}
      >
        <Modal.Header closeButton>
          <div className="h6 d-flex align-items-center"><MDBIcon far icon="comment" className="me-2"/>UGUIS先生とチャット</div>
        </Modal.Header>
        <Modal.Body className="px-1 px-md-3">
          <MDBContainer>
            <MDBRow>
              {this.state.initialChats.map(chat => this.renderChat(chat))}
              {this.state.isInitialDone && this.renderCards()}
              {this.state.followingChats.map(chat => this.renderChat(chat))}
              {this.state.loading && (
                <div className="d-flex flex-row justify-content-start mb-4">
                  <img
                    src="profile.png"
                    className="rounded-circle"
                    height="32"
                    loading="lazy"
                  />
                  <div
                    className="px-4 py-2 ms-2 ms-md-3 fw-bold"
                    style={{
                      borderRadius: "15px",
                      backgroundColor: "var(--light-green)",
                    }}
                  >
                    <div className="small chatloading">
                      ...
                    </div>
                  </div>
                </div>
              )}
            </MDBRow>
            <MDBRow>
              <div className="d-flex flex-row justify-content-end mb-4">
                <textarea id='chatinput' className="form-control me-3"
                  value={this.state.currentChat}
                  onChange={this.handleChatChange}
                  rows={1}
                  onKeyDown={this.onEnterPress} />
                <MDBBtn size="sm" className="green-button" onClick={this.handleChatSubmit}>
                  <MDBIcon fas icon="paper-plane" className="ms-auto"/>
                </MDBBtn>
              </div>
            </MDBRow>
          </MDBContainer>
        </Modal.Body>
      </Modal>
    );
  }
}
