class Storage {
  constructor() {
    if (this.constructor == Storage) {
      throw new Error("Abstract classes can't be instantiated.");
    }
  }
  set() {
    throw new Error("Method 'save()' must be implemented.");
  }
  get() {
    throw new Error("Method 'load()' must be implemented.");
  }
}

class JsonLocalStorage extends Storage {
  constructor(key) {
    super();
    this.key = key;
  }
  set(data) {
    localStorage.setItem(this.key, JSON.stringify(data));
  }
  get() {
    return JSON.parse(localStorage.getItem(this.key));
  }
}

export { Storage, JsonLocalStorage };