import React, { useState, useContext, useEffect } from 'react';

import {
  MDBRow,
  MDBCol,
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon
} from 'mdb-react-ui-kit';

import { AuthContext } from "./Auth/AuthContext"
import Modal from 'react-bootstrap/Modal'
import Login from './Auth/Login'
import SignUp from './Auth/SignUp'

import { Link } from "react-router-dom";

import eventBus from "./EventBus";

function LoginModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      fullscreen={true}
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        {Login()}
      </Modal.Body>
    </Modal>
  );
}

function SignupModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      fullscreen={true}
      centered
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <SignUp inDialog={true} />
      </Modal.Body>
    </Modal>
  );
}

export default function Navibar() {
  const { user, signOut, isLoading, showNavBar, profile } = useContext(AuthContext)
  const [loginModalShow, setLoginModalShow] = React.useState(false);
  const [signupModalShow, setSignupModalShow] = React.useState(false);

  useEffect(() => {
    eventBus.on("login", () =>
      {
        setLoginModalShow(true)
      }
    );
    eventBus.on("signup", () =>
      {
        setSignupModalShow(true)
      }
    );
  }, [])

  useEffect(() => {
    return () => {
      // cleanup
      eventBus.remove("login");
      eventBus.remove("signup");
    };
  }, []);

  function renderLogin() {
    if (user) {
      return (
        <div className="d-flex ms-auto">
          <MDBDropdown className="mx-2">
            <MDBDropdownToggle className="caret-off nav-link" tag='a' role='button'>
              <MDBIcon fas icon="user-circle" size='2x' className="white-text"/>
            </MDBDropdownToggle>
            <MDBDropdownMenu responsive='end' className='user-menu-dropdown'>
              <MDBContainer>
                <MDBRow center className="m-4">
                  <MDBCol>
                    <div className="text-center">
                      <h6>{profile?.email}</h6>
                    </div>
                  </MDBCol>
                </MDBRow>
                <hr className='hr hr-blurry' />
                <MDBDropdownItem link href="/"><span className="fw-bold">英検<sup>®</sup>ライティング学習AI</span></MDBDropdownItem>
                <MDBDropdownItem link href="/correction"><span className="fw-bold">AI英語添削</span></MDBDropdownItem>
                <hr className='hr hr-blurry' />
                <MDBDropdownItem link href="/setting"><MDBIcon icon="cog me-2 text-body" />設定</MDBDropdownItem>
                <hr className='hr hr-blurry' />
                <MDBDropdownItem link href="/term-of-service">利用規約</MDBDropdownItem>
                <MDBDropdownItem link href="/privacy-policy">プライバシーポリシー</MDBDropdownItem>
                <MDBDropdownItem link href="https://www.jiem.co.jp/security/">情報セキュリティ基本方針</MDBDropdownItem>
                <MDBDropdownItem link href="https://support.uguis.ai/portal/ja/kb">お問い合わせ</MDBDropdownItem>
                <MDBDropdownItem link href="https://www.jiem.co.jp/">© 2024 JIEM, Inc.</MDBDropdownItem>
                <hr className='hr hr-blurry' />
                <MDBDropdownItem link className="pb-2" onClick={signOut}>ログアウト</MDBDropdownItem>
              </MDBContainer>
            </MDBDropdownMenu>
          </MDBDropdown>
        </div>
      )
    } else if (!isLoading) {
      return (
        <div className="d-flex ms-auto">
          <MDBBtn
            size="sm"
            className="mx-1 mx-sm-2 px-2 px-sm-4 green-button outline fw-bold lh-sm"
            onClick={() => setSignupModalShow(true)}
          >
            <div>ユーザ登録<br />[無料]</div>
          </MDBBtn>
          <MDBBtn
            size="sm"
            className="mx-1 mx-sm-2 px-2 px-sm-4 green-button outline fw-bold"
            onClick={() => setLoginModalShow(true)}
          >
            ログイン
          </MDBBtn>
          <LoginModal show={loginModalShow} onHide={() => setLoginModalShow(false)} />
          <SignupModal show={signupModalShow} onHide={() => setSignupModalShow(false)} />
        </div>
      )
    }
  }

  return (
    <>
      {showNavBar &&
        <MDBNavbar light>
          <MDBContainer fluid className="justify-content-between">
            <MDBNavbarBrand tag={Link} to='/'>
              <img style={{ 'width': 'min(13rem, 40vw)' }} src={"/color-white-logo-nobackground.svg"} />
            </MDBNavbarBrand>
            {renderLogin()}
          </MDBContainer>
        </MDBNavbar>
      }
    </>
  )
}
