import {Container, Col, Row} from 'react-bootstrap';
import classes from './TermOfService.module.css';

function TermOfService() {
  const terms = {
    "第１条　総則": <div className={classes.indentation}>
      「UGUIS.AI」利用規約（以下「本規約」という）は、株式会社 教育測定研究所（以下「弊社」という）が運営する、英語ライティング練習サービスである「UGUIS.AI」（以下「本サービス」という）の利用者（以下「利用者」という）が遵守すべき事項および利用者と弊社との関係を定めるものです。本サービスの利用者は、本規約の内容を十分理解した上でその内容を遵守することに同意して本サービスを利用するものとします。
    </div>,
    "第２条　定義": <div>
      <div className={classes.indentation}>
        本規約において、以下の用語は以下に定める意味を有するものとします。
      </div>
      <ol className={classes.list_parentheses}>
        <li>「会員」とは、利用者のうち、本規約に同意の上、所定の方法により本サービスに登録した個人をいいます。</li>
        <li>「会員情報」とは、第４条に基づく会員登録手続時に会員がフォームに入力する必要事項をいいます。</li>
        <li>「投稿コンテンツ」とは、利用者が、本サービスを利用して投稿その他送信するコンテンツのことをいいます。</li>
        <li>「アカウント」とは、会員が本サービスを利用するために、弊社が会員に対して発行するサービスID等の会員を識別するための情報をいいます。</li>
      </ol>
    </div>,
    "第３条　本サービスの利用": <div>
      <ol>
        <li>
          利用者は、本規約その他弊社が定める条件に従ってのみ、本サービスを利用することができます。
        </li>
        <li>
          利用者は、利用者としての地位および本サービスの利用により弊社に対して取得した一切の権利義務を譲渡、転貸、担保差入その他形態を問わず処分することはできません。
        </li>
        <li>
          会員ではない利用者は、本サービスのうち、サンプル問題等の限定されたサービスしか受けられません。
        </li>
      </ol>
    </div>,
    "第４条　会員登録手続": <div>
      <ol>
        <li>
          本サービスへの登録を希望する個人は、所定の登録手続完了後に会員としての資格を有します。会員として登録できる者の資格・条件は以下の通りです。
          <ol className={classes.list_parentheses}>
            <li>満18歳以上であること、または未成年である場合には法定代理人の包括的な同意を得ていること。なお、未成年者が、本サービスへ登録した場合には、法定代理人の包括的な同意を得ているものとみなします。</li>
            <li>電子メールアドレスを保有していること。</li>
            <li>既に本サービスの会員となっていないこと。</li>
            <li>本規約の全ての条項に同意すること。</li>
            <li>過去、現在または将来にわたって、暴力団等の反社会的勢力に所属せず、これらのものと関係を有しないこと。</li>
          </ol>
        </li>
        <li>
          会員登録の申込みの前に本規約をよく読み、同意できることを確認してから申込みを行ってください。
        </li>
        <li>
          会員登録申込みの際には、入力上の注意をよく読み、所定の入力フォームに必要事項を正確に入力してください。必要事項をすべて記入した後、フォームを送信することで申込みが完了します。なお、会員登録の申込みを行う者は、登録情報の入力にあたり、入力した情報は全て真実であることを保証するものとします。
        </li>
        <li>
          前項の申込みに基づき、会員資格の付与について所定の審査を実施します。会員登録申込みをおこなった個人が以下の各号に該当する場合、会員として登録することを承諾しない場合があります。また、会員登録後であっても、以下の各号に該当する事実が判明した場合には、弊社は会員登録を取り消すことがあります。
          <ol className={classes.list_parentheses}>
            <li>会員登録の資格・条件を満たさない場合または満たさなくなった場合。</li>
            <li>入力された登録情報に虚偽の情報があることが判明した場合。</li>
            <li>弊社からの連絡または通知を受領できない場合。</li>
            <li>本規約に違反する行為をおこなった場合。</li>
            <li>その他弊社が会員として登録することが不適切であると判断した場合</li>
          </ol>
        </li>
        <li>
          前項の審査の完了後、電子メールによって会員資格の付与について通知します。電子メールの受信をもって会員登録の手続きが完了します。
        </li>
      </ol>
    </div>,
    "第５条	アカウントの管理": <div>
      <ol>
        <li>
          会員は、アカウントおよびパスワードを、会員本人が責任をもって管理、保管するものとし、弊社に無断で、これを第三者に利用させたり、貸与、譲渡、名義変更、売買等をしたりしてはならないものとします。
        </li>
        <li>
          弊社所定の認証方法（アカウントおよび会員が設定したパスワードとの組み合わせによる認証を含みますが、これらに限りません）によってログインされたアカウントによる本サービスの利用があった場合、当該アカウントを有する会員による利用があったものとみなし、それらが盗用、不正使用その他の事情により会員以外の者が利用している場合であっても、それにより生じた損害について弊社は一切責任を負いません。
        </li>
        <li>
          会員は、アカウントまたはパスワードの盗用、第三者による不正利用が判明した場合には、直ちにその旨を弊社に通知するとともに、弊社からの指示に従うものとします。
        </li>
      </ol>
    </div>,
    "第６条　会員情報の変更": <div className={classes.indentation}>
      登録済み会員情報に変更が生じた場合は、速やかに変更登録をお願いいたします。変更登録がなされなかったことにより生じた損害や弊社からの通知の不到達について、弊社は一切責任を負いません。
    </div>,
    "第７条　会員の退会": <div className={classes.indentation}>
      会員が退会を希望する場合には、所定の退会ページより手続を行って下さい。手続完了後、退会となります。
    </div>,
    "第８条　利用料金": <div>
      <ol>
        <li>
          本サービスは、無料でご利用いただけます。
        </li>
        <li>
          利用者は、電話料金やインターネット接続料金、通信機器等、本サービスを利用するために必要な一切の費用を自身で負担するものとします。
        </li>
      </ol>
    </div>,
    "第９条　個人情報": <div>
      <ol>
        <li>
          本サービスにおいて、弊社が会員から収集した個人情報は、本規約に別途定める他、以下のURLに掲載する「個人情報保護方針」に基づき、厳重に取り扱います。
          <div>株式会社 教育測定研究所： <a href="https://www.jiem.co.jp/privacy/">https://www.jiem.co.jp/privacy/</a></div>
        </li>
        <li>
          弊社は、会員による本サービスの利用に際し収集した個人情報を、以下の目的に利用します。
          <ol className={classes.list_parentheses}>
            <li>弊社が提供するサービス（本サービスに限らず、以下「弊社サービス」という）の利用にあたり必要な認証（会員の特定）</li>
            <li>弊社サービスの提供</li>
            <li>弊社サービスの改良・改善</li>
            <li>弊社サービスに関する研究・開発</li>
            <li>会員へのお知らせや各種情報提供（広告等を含みます）</li>
            <li>弊社サービスに関するマーケティング調査、アンケート調査</li>
            <li>弊社サービスの利用に関する統計資料の作成</li>
            <li>会員からのお問合せ、ご相談への対応</li>
            <li>第3項で記載する、第三者への提供</li>
          </ol>
        </li>
        <li>
          弊社は、会員による本サービスの利用に際し収集した個人情報を、弊社サービスの提供、改良及び改善、並びに弊社グループ会社であるDoubleYard Inc.が提供するサービスの研究及び開発のために、以下の外国にあるDoubleYard Inc.に提供します。
          <ul className={classes.ul_disc}>
            <li>外国の名称：米国</li>
            <li>
              <div>当該外国における個人情報の保護に関する制度についての情報：</div>
              <div><a href="https://www.ppc.go.jp/files/pdf/USA_report.pdf">https://www.ppc.go.jp/files/pdf/USA_report.pdf</a></div>
            </li>
            <li>
              <div>当該第三者が講ずる個人情報保護のための措置に関する情報：</div>
              <div>当該第三者はOECD プライバシーガイドライン８原則に対応する個人情報の保護のための措置を講じています。</div>
            </li>
          </ul>
        </li>
        <li>
          本サービスにおける個人情報の取扱いについては、以下のとおりとします。
          <ol className={classes.list_parentheses}>
            <li>
              事業者の氏名または名称
              <div>株式会社 教育測定研究所</div>
            </li>
            <li>
              個人情報保護管理者
              <div>株式会社 教育測定研究所　個人情報保護管理者</div>
            </li>
            <li>
              個人情報を入力するにあたっての注意事項
              <div>会員が弊社に対して個人情報を提供することは任意となります。ただし、個人情報を提供されない場合には、本サービスの全部または一部をご利用いただけない場合があります。</div>
            </li>
            <li>
              安全管理措置の実施
              <div>弊社は、個人情報が社外に流出する、不当に改ざんされるなどといったトラブルを引き起こさないように社内規程を整備し、安全管理措置を講じます。</div>
            </li>
            <li>
              個人情報の開示等の求めおよびお問合せ窓口について
              <div>弊社が保有する個人情報の利用目的の通知、開示、訂正・追加または削除、利用・消去および第三者への提供の停止に関するお問合せは以下のとおりです。</div>
              <div>〔個人情報苦情および相談窓口〕</div>
              <div>株式会社教育測定研究所　個人情報取り扱いに関する苦情・相談窓口</div>
              <div>電話：03-6897-3856</div>
              <div>E-mail：<a href="mailto:contact@jiem.co.jp">contact@jiem.co.jp</a></div>
              <div>午前9時30分〜午後5時30分（土・日曜日、祝祭日、年末年始は除く）</div>
            </li>
          </ol>
        </li>
      </ol>
    </div>,
    "第１０条　本サービスの中断・停止等": <div>
      <ol>
        <li>
          弊社は、データのバックアップなど、本サービスに必要なメンテナンスを定期的に実施し、その間、本サービスの提供を停止することがあります。
        </li>
        <li>
          前項に基づき本サービスの提供を停止する場合には、弊社は、停止する時期を本サービスのサイトに予め記載するものとします。但し、以下のいずれかの事由が発生した場合には、弊社は、停止する時期を本サービスのサイトに予め記載することなく、本サービスの全部または一部の提供を停止することができるものとします。
          <ol className={classes.list_parentheses}>
            <li>天災、事変、その他の非常事態が発生し、または発生する恐れがある場合</li>
            <li>弊社のシステムやネットワークの保守を緊急に行う場合</li>
            <li>弊社が設置する電気通信設備の障害その他やむを得ない事由が発生した場合</li>
            <li>その他、弊社が本サービスの提供の全部または一部を停止する必要があると判断した場合</li>
          </ol>
        </li>
      </ol>
    </div>,
    "第１１条　会員の利用停止・会員資格取消": <div>
      <ol>
        <li>
          以下のいずれかの事由が発生した場合には、弊社は、当該会員による本サービスの利用停止、利用禁止または会員登録の抹消をすることができるものとします。
          <ol className={classes.list_parentheses}>
            <li>会員が虚偽の情報を申告または申請した場合</li>
            <li>会員が過去に本サービスの利用停止、利用禁止または登録抹消をされていた場合</li>
            <li>会員が本規約に違反した場合</li>
            <li>会員が本サービスの提供や運営等に支障を与え、または他の会員もしくは弊社の権利・利益等を害するおそれがあると弊社が判断した場合</li>
          </ol>
        </li>
        <li>
          弊社は、前項各号のいずれかに該当するか否かを確認するため、いつでも、電子メール、電話その他の手段により、会員に対して、問合せをすることができるものとします。当該問合せに応じない場合等には、弊社は、当該会員の利用停止、利用禁止または会員登録の抹消をすることができるものとします。
        </li>
      </ol>
    </div>,
    "第１２条　サービスの変更・廃止": <div className={classes.indentation}>
      弊社は、その判断により本サービスの全部または一部を適宜変更・廃止できるものとします。
    </div>,
    "第１３条　サポート対応等": <div>
      <div className={classes.indentation}>弊社は、下記に定めるお問い合わせフォームに限り、本サービスに関するサポートを受け付けます。</div>
      <div className={classes.indentation}>お問い合わせフォームは<a href="https://support.uguis.ai/portal/ja/kb">こちら</a>です。</div>
    </div>,
    "第１４条　権利帰属について": <div>
      <ol>
        <li>
          本サービスに掲載されている商号・商標をはじめとし、文書、イラスト、画像等の内容に関する著作権などの知的財産権の全ての権利は、弊社または固有の権利所有者に帰属し、各関連法令などによって保護されています。事前に弊社から文書による承諾を得ることなく、私的利用その他法律によって明示的に認められる範囲を超えて、これらの一部または全部をそのままあるいは改変して転用、複製などをすることは一切禁止します。
        </li>
        <li>
          利用者は、投稿コンテンツについて、自らが投稿その他送信することについての適法な権利を有していること、及び投稿コンテンツが第三者の権利を侵害していないことについて、弊社に対し表明し、保証するものとします。
        </li>
        <li>
          投稿コンテンツに関する著作権は、利用者または当該コンテンツの著作権者に帰属します。利用者は、弊社に対し、投稿コンテンツに関して、日本の国内外で無償かつ非独占的に、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示および実行することについて許諾するものとします。利用者は、弊社および弊社から権利を承継し、または許諾された者に対して著作者人格権を行使しないことに同意します。
        </li>
      </ol>
    </div>,
    "第１５条　広告配信サービスおよびクッキー情報等について": <div>
      <ol>
        <li>
          本サービスでは、Google、ヤフー、Facebook、Twitterを含む第三者配信事業者 （以下「第三者配信事業者」という）により、弊社のメディア事業に関連して弊社が承認する広告主の広告が掲載される場合があります。また、第三者配信事業者により、本サービス以外のインターネット上の様々なサイトやソーシャルネットワーキングサービス等に、弊社のメディア事業に関連して弊社が承認する広告主の広告が掲載される場合があります。
        </li>
        <li>
          前項に関連して、弊社は、利用者の興味、関心にあった最適かつ有益な広告を配信することを目的として、本サービスを訪問した利用者のクッキー情報等の識別情報、本サービスへの過去のアクセス情報（会員の登録情報を含む）等を取得、蓄積または利用し、また、第三者配信事業者に対し、これらの情報を提供し、または直接取得させる仕組みを許容する場合があります。ただし、弊社から提供され、または、第三者配信事業者が直接取得するこれらの情報はいずれも暗号化されており、個人を特定できる情報は一切含まれておりません。
        </li>
        <li>
          第三者配信事業者によって取得された前項の情報等は、当該第三者配信事業者がそれぞれ独自に定めるプライバシーポリシーその他の情報取扱い方針等に従って取り扱われます。
        </li>
        <li>
          利用者は、以下に定める第三者配信事業者のウェブサイト内に設けられたオプトアウトページにアクセスして、当該第三者配信事業者によるクッキー情報や識別情報等の広告配信への利用を停止することができます。
          <div className={classes.separation}>
            <div>Facebook：</div>
            <div>プライバシー：データに関するポリシー</div>
            <div><a href="https://www.facebook.com/policy.php">https://www.facebook.com/policy.php</a></div>
            <div>Facebook広告のオプトアウト</div>
            <div><a href="https://www.facebook.com/login.php?next=https%3A%2F%2Faccountscenter.facebook.com%2Fads">https://www.facebook.com/login.php?next=https%3A%2F%2Faccountscenter.facebook.com%2Fads</a></div>
          </div>
          <div className={classes.separation}>
            <div>X：</div>
            <div>プライバシーポリシー</div>
            <div><a href="https://twitter.com/privacy?lang=ja">https://twitter.com/privacy?lang=ja</a></div>
            <div>パーソナライズド広告の表示設定について </div>
            <div><a href="https://help.twitter.com/ja/safety-and-security/privacy-controls-for-tailored-ads">https://help.twitter.com/ja/safety-and-security/privacy-controls-for-tailored-ads</a></div>
          </div>
          <div className={classes.separation}>
            <div>LINEヤフー：</div>
            <div>プライバシーポリシー</div>
            <div><a href="https://www.lycorp.co.jp/ja/company/privacypolicy/">https://www.lycorp.co.jp/ja/company/privacypolicy/</a></div>
            <div>LINE行動ターゲティング広告のオプトアウト</div>
            <div><a href="https://optout.tr.line.me/#">https://optout.tr.line.me/#</a></div>
          </div>
          <div className={classes.separation}>
            <div>Amazon ターゲティング広告：</div>
            <div>規約</div>
            <div><a href="https://www.amazon.co.jp/gp/help/customer/display.html?nodeId=G64JFZVFDY66XG9K">https://www.amazon.co.jp/gp/help/customer/display.html?nodeId=G64JFZVFDY66XG9K</a></div>
            <div>広告配信における表示の設定変更</div>
            <div><a href="https://www.amazon.co.jp/adprefs">https://www.amazon.co.jp/adprefs</a></div>
          </div>
          <div className={classes.separation}>
            <div>ブレインパッド：</div>
            <div>個人情報の取扱（オプトアウト方法も記載）</div>
            <div><a href="https://www.brainpad.co.jp/utility/privacy.html">https://www.brainpad.co.jp/utility/privacy.html</a></div>
          </div>
          <div className={classes.separation}>
            <div>エバーライズ：</div>
            <div>個人情報保護方針（オプトアウト方法も記載）</div>
            <div><a href="https://www.ever-rise.co.jp/privacy">https://www.ever-rise.co.jp/privacy</a></div>
          </div>
          <div className={classes.separation}>
            <div>ネットワーク広告イニシアチブ（NAI）加盟の第三者配信事業者の広告配信：</div>
            <div>当該事業者によるCookie使用の無効化・オプトアウト</div>
            <div><a href="https://optout.networkadvertising.org/?c=1#!%2F">https://optout.networkadvertising.org/?c=1#!%2F</a></div>
          </div>
          <div className={classes.separation}>
            <div>デジタル広告連合（DAA）加盟の第三者配信事業者の広告配信：</div>
            <div>当該事業者によるCookie使用の無効化・オプトアウト</div>
            <div><a href="https://www.aboutads.info/choices/">https://www.aboutads.info/choices/</a></div>
          </div>
          <div className={classes.separation}>
            <div>Data Driven Advertising Initiative（DDAI）加盟の第三者配信事業者の広告配信：</div>
            <div>当該事業者によるCookie使用の無効化・オプトアウト</div>
            <div><a href="http://www.ddai.info/optout">http://www.ddai.info/optout</a></div>
          </div>
        </li>
      </ol>
    </div>,
    "第１６条　禁止事項": <div>
      <div className={classes.indentation}>本サービスの利用に際して、利用者に対し次の各号の行為を行うことを禁止します。</div>
      <ol className={classes.list_parentheses}>
        <li>本規約または法令に違反すること</li>
        <li>弊社およびその他の第三者の権利、利益、名誉等を損ねること</li>
        <li>青少年の心身に悪影響を及ぼす恐れがある行為、その他公序良俗に反する行為</li>
        <li>他の利用者その他の第三者に迷惑となる行為や不快感を抱かせる行為</li>
        <li>虚偽の情報を入力すること</li>
        <li>有害なコンピュータープログラム、メール等を送信または書き込むこと</li>
        <li>弊社のサーバその他のコンピュータに不正にアクセスすること</li>
        <li>アカウントおよびパスワードを第三者に貸与・譲渡すること、または第三者と共用すること</li>
      </ol>
    </div>,
    "第１７条　免責": <div>
      <ol>
        <li>
          弊社は、利用者が、弊社が提供する本サービスを利用したことにより、または利用できなかったことにより発生した一切の損害について、弊社の故意または重過失に基づくものを除き、いかなる責任も負わないものとします。
        </li>
        <li>
          弊社は、利用者が本サービスや本サービスの設備に蓄積したデータに対する、第三者による削除や改ざんについて、いかなる責任も負わないものとします。
        </li>
        <li>
          本サービスの提供、変更、遅滞、中止もしくは廃止、本サービスを利用して登録、提供される情報等の流失もしくは消失等、その他本サービスに関連して発生した利用者の損害について、弊社の故意または重過失に基づくものを除き、弊社は、いかなる責任も負わないものとします。 また、弊社は、いかなる場合も、利用者の逸失利益または特別な事情から生じた損害（損害発生につき弊社が予見し、または予見し得た場合を含む）について、いかなる責任も負わないものとします。
        </li>
      </ol>
    </div>,
    "第１８条　本規約の改定": <div>
      <ol>
        <li>
          弊社は、次に掲げる場合には、利用者の個別の承諾を得ることなく必要に応じて本規約を任意に改定できるものとし、本規約に追加の規定、条件等を定めることができます。
          <ol className={classes.list_parentheses}>
            <li>本規約の変更が、利用者の一般の利益に適合するとき</li>
            <li>本規約の変更が、契約をした目的に反せず、かつ、変更に係る事情に照らして合理的なものであるとき</li>
          </ol>
        </li>
        <li>
          前項の定めによって本規約の変更をする場合は、次の事項につき、本サービスのサイト上に表示します。
          <ol className={classes.list_parentheses}>
            <li>効力発生時期</li>
            <li>本規約を変更する旨</li>
            <li>変更後の本規約の内容</li>
          </ol>
        </li>
        <li>
          弊社が第1項第2号に定める事由によって本規約の変更を行う場合、効力発生時期が到来するまでに前項各号に定める事項を本サービスのサイト上に表示します。
        </li>
      </ol>
    </div>,
    "第１９条　準拠法・合意管轄": <div>
      <ol>
        <li>
          本サービスの利用並びに本規約等の解釈・運用は、別段の定めのない限り、日本法に準拠するものといたします。本規約の一部が無効な場合でも、適用可能な項目については効力があるものとします。
        </li>
        <li>
          本サービスの利用に関連して紛争が発生した場合には、東京地方裁判所または東京簡易裁判所を第一審の専属管轄裁判所とします。
        </li>
      </ol>
    </div>
  }

  const termContents = Object.keys(terms).map((k, i) => {
    return (<div key={i}>
      <div className={classes.article}>{k}</div>
      <div className={classes.content}>{terms[k]}</div>
    </div>)
  })

  return (
    <Container className="my-3">
      <Row className="justify-content-center">
        <Col>
          <h3 className="fw-bold">利用規約</h3>
          {termContents}
          <div style={{textAlign: "right"}}>以上</div>
        </Col>
      </Row>
    </Container>
  )
}

export default TermOfService;
