import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput
}
  from 'mdb-react-ui-kit';

import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { confirmSignUp } from "./auth"

import Alert from 'react-bootstrap/Alert';

export default function ConfirmSignUp() {
  let { param_email, param_code } = useParams();
  console.log('param_email', param_email)
  const [email, setEmail] = useState(param_email ?? "")
  const [code, setCode] = useState(param_code ?? "")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const confirmAsync = async (email, code) => {
    if (email && code) {
      try {
        await confirmSignUp(email, code)
        setSuccess(true)
      } catch (err) {
        if (err.name == 'CodeMismatchException') {
          setError('コードが間違っています。')
        }
        else if (err.name == 'ExpiredCodeException') {
          setError('コードが期限切れになっています。')
        }
        else {
          setError(err.message)
        }
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("")
    await confirmAsync(email, code)
  }

  useEffect(() => {
    confirmAsync(email, code);
  }, []);

  function renderContent() {
    if (success) {
      return (
        <section className="text-center">
          <h5 class="mb-3">アクセスコードを確認できました。サービスにログインしてください。</h5>
          <MDBBtn 
            size='lg'
            className="mb-5 green-button confirm-page-button" 
            href='/login'
          >
            ログイン
          </MDBBtn>
        </section>
      )
    }
    else {
      return (
        <>
          <div className="text-center mb-3">登録したメールアドレスに届いたアクセスコードを入力してください</div>
          <MDBRow center className="text-center mb-3">
            <MDBCol size="10">
              <MDBInput size='lg' label='メールアドレス' type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
            </MDBCol>
          </MDBRow>
          <MDBRow center className="text-center mb-3">
            <MDBCol size="10">
              <MDBInput
                size='lg'
                label='アクセスコード'
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow center className="text-center mb-3">
            <MDBCol>
              <MDBBtn 
                size="lg" 
                className="mb-4 green-button confirm-page-button" 
                onClick={handleSubmit}
              >
                確認
              </MDBBtn>
            </MDBCol>
          </MDBRow>
          {error &&
            <MDBRow center className="text-center mb-3">
              <MDBCol md="8">
                <Alert variant="danger">
                  <Alert.Heading>アクセスコードの確認が失敗しました</Alert.Heading>
                  <div>{error}</div>
                </Alert>
              </MDBCol>
            </MDBRow>
          }
          <MDBRow center className="text-center mb-3">
            <MDBCol md="8">
              <MDBRow center className="mt-4">アクセスコードをお持ちでない方</MDBRow>
              <MDBRow center className="mt-1">
                <MDBCol>
                  <MDBBtn
                    size="lg"
                    className='green-button outline confirm-page-button' 
                    href="/signUp"
                  >
                    ユーザ登録【無料】
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </MDBCol>
          </MDBRow>
        </>
      )
    }
  }

  return (
    <MDBContainer className="py-5">
      <MDBRow center>
        <MDBCol md={8}>
          <MDBContainer>
            <MDBRow className="text-center mb-5">
              <MDBCol>
                <a href="/">
                  <img src="/color-logo-nobackground.svg"
                    style={{ 'width': '75vw', 'maxWidth': '400px' }} alt="logo" />
                </a>
                <h5 className="mt-1 mb-5">
                  <div>合格への近道は練習にあり！</div>
                  <div>英語ライティング学習サービス</div>
                </h5>
              </MDBCol>
            </MDBRow>
            <MDBRow className="text-center mb-3">
              <h5>アクセスコードの確認</h5>
            </MDBRow>
            {renderContent()}
          </MDBContainer>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
