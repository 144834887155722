import React, { Component } from "react";
import {Container, Row, Col} from 'react-bootstrap';
import eventBus from "../EventBus";
import {
  MDBIcon, MDBCard, MDBCardBody, MDBBtn, MDBCardTitle, MDBCardText, MDBRipple
}
from 'mdb-react-ui-kit';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import ContentCoherenceFeedbackCard from './ContentCoherenceFeedbackCard'
import GrammarVocabularyFeedbackCard from './GrammarVocabularyFeedbackCard'

class FeedbackTabContent extends Component {
  constructor(props) {
    super(props);

    props.direction ??= "vertical";
    props.type ??= "tag";

    this.state = {
      selectedId: null,
      hoveredId: null
    };
  }

  cardOnClick = (e) => {
    e.preventDefault();
    let feedbackTag = e.currentTarget.getAttribute('feedbackid')
    this.cardSelected(feedbackTag);
    // console.log('select card onlick', e.currentTarget, feedbackTag)
    this.selectCard({ type: this.props.type, id: feedbackTag });
  }

  onSwiperAfterInit(swiper) {
    this.swiper = swiper;
    let feedbackTag = swiper.slides[swiper.activeIndex]?.getAttribute("id");
    this.cardSelected(feedbackTag);
  }

  onSwiperSlideChange(swiper) {
    let feedbackTag = swiper.slides[swiper.activeIndex]?.getAttribute("id");
    this.cardSelected(feedbackTag);
  }

  cardSelected(id) {
    if (id != null){
      eventBus.dispatch("selectCard", { type: this.props.type, id: id });
    }
  }

  cardOnMouseOver = (e) => {
    e.preventDefault();
    let tag = e.currentTarget.getAttribute('feedbackid')
    eventBus.dispatch("hoverCard", { type: this.props.type, id: tag });
  }

  cardOnMouseOut = (e) => {
    e.preventDefault();
    let tag = e.currentTarget.getAttribute('feedbackid')
    eventBus.dispatch("unhoverCard", { type: this.props.type, id: tag });
  }

  scrollCardIntoView(id) {
    // console.log('scrollCardIntoView', id)
    const element = document.getElementById(`feedbackcard-${id}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }

  selectCard(card) {
    // console.log('select card', card, this.props.type)
    if (card.type == this.props.type) {
      // for vertical
      this.setState(prevState => ({
        ...prevState,
        selectedId: card.id,
      }))
      this.scrollCardIntoView(card.id)

      // for horizontal
      if (this.swiper && this.swiper.slides) {
        let index = this.swiper.slides.findIndex(slide => slide.getAttribute("id") == card.id);
        if (index >= 0) {
          this.swiper.slideTo(index, 500, false);
        }
      }
    }
  }

  highlightCard(card) {
    if (card.type == this.props.type) {
      this.setState(prevState => ({
        ...prevState,
        hoveredId: card.id,
      }))
      this.scrollCardIntoView(card.id)
    }
  }

  lowlightCard(card) {
    this.setState(prevState => ({
      ...prevState,
      hoveredId: null,
    }))
  }

  componentDidMount() {
    if (eventBus.storage.selectedCard) {
      this.selectCard(eventBus.storage.selectedCard)
    }

    eventBus.on("selectEditor", (data) =>
      {
        this.selectCard(data)
      }
    );
    eventBus.on("hoverEditor", (data) =>
      {
        this.highlightCard(data)
      }
    );
    eventBus.on("unhoverEditor", (data) =>
      {
        // console.log('unhover')
        this.lowlightCard(data)
      }
    );
  }

  componentWillUnmount() {
    eventBus.remove("selectEditor");
    eventBus.remove("hoverEditor");
    eventBus.remove("unhoverEditor");
  }

  render() {
    if (this.props.feedbacks == null){
      return null;
    }

    if (this.props.direction == 'vertical') {
      return this.props.feedbacks.map((feedback, i) => {
        return this.props.type == "tag" ? (
          <ContentCoherenceFeedbackCard
            id={feedback.tag}
            feedback ={feedback}
            selected = {feedback.tag == this.state.selectedId}
            hovered = {feedback.tag == this.state.hoveredId}
            onClick={this.cardOnClick}
            onMouseOver={this.cardOnMouseOver}
            onMouseOut={this.cardOnMouseOut}/>
          ) : (
            <GrammarVocabularyFeedbackCard
              id={i}
              feedback ={feedback}
              selected={i == this.state.selectedId}
              hovered={i == this.state.hoveredId}
              onClick={this.cardOnClick}
              onMouseOver={this.cardOnMouseOver}
              onMouseOut={this.cardOnMouseOut}/>
          );
      });
    }
    else {
      return (
        <Swiper
          autoHeight={true}
          spaceBetween={50}
          slidesPerView={1}
          onAfterInit={(swiper) => {this.onSwiperAfterInit(swiper)}}
          onSlideChange={(swiper) => {this.onSwiperSlideChange(swiper)}}
          pagination={true}
          modules={[Pagination]}>
          {
            this.props.feedbacks.map((feedback, i) => {
              let card = this.props.type == "tag" ? (
                <ContentCoherenceFeedbackCard
                  feedback ={feedback}
                  selected = {true}/>
                ) : (
                  <GrammarVocabularyFeedbackCard
                    id={i}
                    feedback ={feedback}
                    selected = {true}/>
                )
              if (!card) return null;
              return (
                <SwiperSlide id={feedback.tag}>
                  <div className="pb-3">
                    {card}
                  </div>
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      )
    }
  }
}

export default FeedbackTabContent;
