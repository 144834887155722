import { Amplify } from 'aws-amplify';
import { Hub } from 'aws-amplify/utils';
import * as Auth from 'aws-amplify/auth'
import { config } from "./amplifyConfig"
import * as gtm from "../services/gtm"
import crypto from 'crypto-js';

Amplify.configure(config);

export const hashUserId = (userId) => {
  return crypto.SHA256(userId).toString();
};

export function signUp(email, password) {
  return new Promise(async (resolve, reject) => {
    try{
      const result = await Auth.signUp({
        username: email,
        password: password,
        options: {
          userAttributes: {
            email: email,
            given_name: email
          },
        }
      });
      console.debug('result', result);
      gtm.sendSignUpEvent();
      resolve(result);
    }
    catch(err){
      reject(err)
    }
  })
}

export function confirmSignUp(username, confirmationCode) {
  return new Promise(async (resolve, reject) => {
    try{
      const result = await Auth.confirmSignUp({
        username: username,
        confirmationCode: confirmationCode
      })
      console.debug('result', result);
      gtm.sendConfirmSignUpEvent()
      resolve(result)
    }
    catch(err){
      console.debug('err', err)
      reject(err)
    }
    return
  })
}

export function signIn(username, password) {
  return new Promise(async (resolve, reject) => {
    try{
      if(isSignedIn()){
        await Auth.signOut()
      }
      await Auth.signIn({
        username: username,
        password: password,
      })
      resolve()
    }
    catch(err){
      reject(err)
    }
  })
}

export function forgotPassword(username) {
  return Auth.resetPassword({
    username: username
  });
}

export function confirmPassword(username, confirmationCode, newPassword) {
  return Auth.confirmResetPassword({
    username: username,
    confirmationCode: confirmationCode,
    newPassword: newPassword,
  })
}

export function signOut() {
  if(isSignedIn()){
    return Auth.signOut()
  }
}

export function getCurrentUser() {
  return new Promise(async (resolve, reject) => {
    try{
      const user = await Auth.getCurrentUser()
      resolve(user)
    }
    catch(err){
      console.debug('failed to get current user', err)
      reject(err)
    }
  })
}

export function getSession() {
  return Auth.fetchAuthSession()
}

export async function changePassword(oldPassword, newPassword) {
  if(!isSignedIn()){
    return
  }
  return Auth.updatePassword({
    oldPassword: oldPassword,
    newPassword: newPassword
  })
}

export async function deleteUser() {
  if(!isSignedIn()){
    return
  }
  return Auth.deleteUser()
}

export async function isSignedIn() {
  try{
    const user = await getCurrentUser()
    return user ? true : false
  }
  catch(err){
    return false
  }
}

export async function signInWithGoogle() {
  if(isSignedIn()){ // If the user is already signed in, sign out first to prevent auth errors
    signOut()
  }
  gtm.sendLoginWithGoogleEvent();
  return Auth.signInWithRedirect({ provider: 'Google' })
}

Hub.listen('auth', async ({ payload }) => {
  console.log(payload.event);
  switch (payload.event) {
    case 'signedIn':
      console.log('user have been signedIn successfully.');
      const user = await getCurrentUser()
      const userHash = hashUserId(user.username)
      gtm.setUserId(userHash);
      gtm.sendLoginEvent();
      break;
    case 'signedOut':
      console.log('user have been signedOut successfully.');
      gtm.sendLogoutEvent()
      gtm.clearUserId()
      break;
    case 'tokenRefresh':
      console.log('auth tokens have been refreshed.');
      break;
    case 'tokenRefresh_failure':
      console.log('failure while refreshing auth tokens.');
      break;
    case 'signInWithRedirect':
      console.log('signInWithRedirect API has successfully been resolved.');
      break;
    case 'signInWithRedirect_failure':
      console.log('failure while trying to resolve signInWithRedirect API.');
      break;
  }
});