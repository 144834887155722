import {
  MDBIcon
} from 'mdb-react-ui-kit';

function checkPasswordLength(password) {
  return password.length >= 8;
}

function checkPasswordNumber(password) {
  return /\d/.test(password);
}

function checkPasswordAlphabet(password) {
  return /[a-zA-Z]/.test(password);
}

function checkConfirmPassword(password, confirmPassword) {
  return password === confirmPassword
}

function checkPassword(password, confirmPassword) {
  const passwordChecks = [
    () => checkPasswordLength(password),
    () => checkPasswordNumber(password),
    () => checkPasswordAlphabet(password),
    () => checkConfirmPassword(password, confirmPassword),
  ];
  return passwordChecks.every(check => check())
}

function PasswordChecker({ password, confirmPassword }) {

  const passwordChecks = [
    {
      check: () => checkPasswordLength(password),
      message: '英数字8文字以上',
    },
    {
      check: () => checkPasswordNumber(password),
      message: '少なくとも1つの数字を含める',
    },
    {
      check: () => checkPasswordAlphabet(password),
      message: '少なくとも1つの英字を含める',
    },
    {
      check: () => checkConfirmPassword(password, confirmPassword),
      message: '入力されたパスワードが一致している',
    },
  ];

  const passwordChecker = (checker, description) => {
    return (
      <div className={checker() ? "green-text" : "red-text"}>
        <MDBIcon icon="check me-1 green-text" className={checker() ? "" : "d-none"}/>
        <MDBIcon icon="times me-1 red-text" className={checker() ? "d-none" : ""}/>
        {description}
      </div>
    )
  }

  return (
    <div className={password ? "" : "d-none"}>
      <div className="lh-sm small">
        {passwordChecks.map(({ check, message }, index) => (
          <div key={index}>{passwordChecker(check, message)}</div>
        ))}
      </div>
    </div>
  )
}

export { checkPassword, PasswordChecker }
