const Diff = require('diff');

class DiffEx {
  static mergeDiffs(diff) {
    const mergedDiff = [];
    let currentRemovedWords = '';
    let currentAddedWords = '';
    diff.forEach((part) => {
      // console.log(part);
      if (!part.removed && !part.added) {
        if (part.value != ' ') {
          if (currentRemovedWords) {
            mergedDiff.push({
              value: currentRemovedWords,
              added: false,
              removed: true,
            });
          }
          if (currentAddedWords) {
            mergedDiff.push({
              value: currentAddedWords,
              added: true,
              removed: false,
            });
          }
          mergedDiff.push(part);
          currentRemovedWords = '';
          currentAddedWords = '';
        } else {
          currentAddedWords += ' ';
          currentRemovedWords += ' ';
        }
      } else if (part.removed) {
        currentRemovedWords += part.value;
      } else if (part.added) {
        currentAddedWords += part.value;
      }
    });
    if (currentRemovedWords) {
      mergedDiff.push({
        value: currentRemovedWords,
        added: false,
        removed: true,
      });
    }
    if (currentAddedWords) {
      mergedDiff.push({
        value: currentAddedWords,
        added: true,
        removed: false,
      });
    }

    return mergedDiff;
  }

  static diff(org_txt, correct_txt, diffWords=true) {
    const diffs =  diffWords ? Diff.diffWords(org_txt ?? '', correct_txt ?? '') : Diff.diffChars(org_txt ?? '', correct_txt ?? '')
    const mergedDiff = DiffEx.mergeDiffs(diffs);

    const convertedDiff = [];
    let currentRemoveDiff = null;
    mergedDiff.forEach((part) => {
      // DiffEx returns 3 types of actions "added", "removed", "replaced"
      if (!part.added && !part.removed) {
        if (currentRemoveDiff) {
          convertedDiff.push({
            from: currentRemoveDiff.value,
            to: null,
            action: 'removed'
          });
          currentRemoveDiff = null;
        }
        convertedDiff.push({
          from: part.value,
          to: part.value,
          action: null
        })
      }
      else if (part.removed) {
        currentRemoveDiff = part;
      }
      else if (part.added) {
        if (currentRemoveDiff) {
          convertedDiff.push({
            from: currentRemoveDiff.value,
            to: part.value,
            action: 'replaced'
          });
          currentRemoveDiff = null;
        }
        else {
          convertedDiff.push({
            from: null,
            to: part.value,
            action: 'added'
          });
        }
      }
    })
    if (currentRemoveDiff) {
      convertedDiff.push({
        from: currentRemoveDiff.value,
        to: null,
        action: 'removed'
      });
      currentRemoveDiff = null;
    }
    return convertedDiff;
  }
}

export default DiffEx;
