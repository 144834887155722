// device.js
export const isIOSApp = () => {
  const userAgent = navigator.userAgent;
  return /uguis\.ios(\/\d+\.\d+\.\d+)?/.test(userAgent);
};

export const getIOSAppVersion = () => {
  const userAgent = navigator.userAgent;
  const match = userAgent.match(/uguis\.ios\/(\d+\.\d+\.\d+)/);
  return match ? match[1] : null;
};

export const isNewerOrEqualVersion = (currentVersion, targetVersion) => {
  const current = currentVersion.split('.').map(Number);
  const target = targetVersion.split('.').map(Number);

  for (let i = 0; i < 3; i++) {
    if (current[i] > target[i]) return true;
    if (current[i] < target[i]) return false;
  }

  return true; // 全てのバージョン番号が等しい場合
};

export const isOlderOrEqualVersion = (currentVersion, targetVersion) => {
  const current = currentVersion.split('.').map(Number);
  const target = targetVersion.split('.').map(Number);

  for (let i = 0; i < 3; i++) {
    if (current[i] < target[i]) return true;
    if (current[i] > target[i]) return false;
  }

  return true; // 全てのバージョン番号が等しい場合
};

export const isIOSAppVersionNewerThanOrEqualTo = (targetVersion) => {
  const currentVersion = getIOSAppVersion();
  if (!currentVersion) return false; // バージョン情報がない場合は false を返す
  return isNewerOrEqualVersion(currentVersion, targetVersion);
};

export const isIOSAppVersionOlderThanOrEqualTo = (targetVersion) => {
  const currentVersion = getIOSAppVersion();
  if (!currentVersion) return false; // バージョン情報がない場合は false を返す
  return isOlderOrEqualVersion(currentVersion, targetVersion);
};
