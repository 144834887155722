import {
  useLocation,
  useNavigate
} from "react-router-dom"

export function withRouter(Children) {
  return (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    return <Children {...props}
    navigate = { navigate }
    location = { location }
    />
  }
}
