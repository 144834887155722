import Star from './Star';

function Score(props) {
  const max_score = props.max_score == null ? 4 : props.max_score;
  const stars = Array.from(Array(max_score)).map((_, i) => {
    return <Star key={i} score={props.score>i ? 1 : 0} size={props.size}/>
  });
  return (
    <span>
      {stars}
    </span>
  );
}

export default Score;
