import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-5ZFNDB4F'
};

export const initializeGTM = (initialUserId) => {
  const args = {
    ...tagManagerArgs,
    dataLayer: {
      user_id: initialUserId
    }
  };
  TagManager.initialize(args);
};

export const pushToDataLayer = (data) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};

export const setUserId = (user_id) => {
  pushToDataLayer({ user_id });
};

export const clearUserId = () => {
  pushToDataLayer({ user_id: null });
};

export const sendPageView = (page) => {
  pushToDataLayer({
    event: 'pageview',
    page
  });
};

export const sendLoginEvent = () => {
  pushToDataLayer({
    event: 'login'
  });
};

export const sendLoginWithGoogleEvent = () => {
  pushToDataLayer({
    event: 'loginWithGoogle'
  });
};

export const sendLogoutEvent = () => {
  pushToDataLayer({
    event: 'logout'
  });
};

export const sendSignUpEvent = () => {
  pushToDataLayer({
    event: 'signUp'
  });
};

export const sendConfirmSignUpEvent = () => {
  pushToDataLayer({
    event: 'confirmSignUp'
  });
};


export const sendGenerationQuestionEvent = (grade, type, subtopic, time_taken) => {
  pushToDataLayer({
    event: 'generate-question',
    grade,
    type,
    subtopic,
    time_taken
  })
  //Reset metadata to ensure future events remain clean.
  pushToDataLayer({
    grade: null,
    type: null,
    subtopic: null,
    time_taken: null
  })
};

export const sendScoreEvent = (grade, type, score, time_taken) => {
  pushToDataLayer({
    event: 'socre-assignment',
    grade,
    type,
    score,
    time_taken
  })
  //Reset metadata to ensure future events remain clean.
  pushToDataLayer({
    grade: null,
    type: null,
    score: null,
    time_taken: null
  })
}