import { MDBIcon } from 'mdb-react-ui-kit';
function Star(props) {
  return (
    <span>
      <MDBIcon fas icon="star" style={{color: "orange", display: props.score>0 ? '' : 'none'}} size={props.size}/>
      <MDBIcon far icon="star" style={{display: props.score==0 ? '' : 'none'}} size={props.size}/>
    </span>
  )
}

export default Star;
