export const config = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_COGNITO_DOMAIN,
          redirectSignOut: [window.location.origin + "/"],
          redirectSignIn: [window.location.origin + "/"],
          responseType: "code",
          scopes: ["email", "openid", "profile", "aws.cognito.signin.user.admin"]
        }
      },
      signUpVerificationMethod: "code",
      userAttributes: {
        email: {
          required: true,
        },
      },
      allowGuestAccess: false,
      passwordFormat: {
        minLength: 8,
        requireLowercase: true,
        requireUppercase: false,
        requireNumbers: true,
        requireSpecialCharacters: false,
      },
    },
  }
};