import Score from './Score';

function HeaderedScore(props) {
  if (props.score == null){
    return null;
  }
  return (
    <span>{props.header} <Score score={props.score} max_score={props.max_score} size={props.size}/></span>
  );
}

export default HeaderedScore;
